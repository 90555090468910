@import "./normalize.css";

@import "./colors.scss";

* {
  box-sizing: border-box;
}

body {
  background: $color1;
  color: $color5;
}

form {
  display: flex;
}

input[type="text"] {
  flex-grow: 1;
  background-color: $color5;
  border: 1px solid $color2;
  border-radius: 3px;
  color: $color2;
}

button {
  background-color: $color5;
  color: $color2;
  border: none;
  border-radius: 3px;
  font-weight: bold;

  &:hover {
    background-color: $color2;
    color: $color5;
    border: 1px solid $color5;
  }
}

.App {
  min-height: 80vh;
  max-width: 1000px;
  margin: 1% auto;
  background: $color2;
  padding: 3%;
}

.task {
  &.completed {
    .description {
      color: $color3;
      text-decoration: line-through;
    }
  }

  &:hover {
    background-color: $color3;
    &.completed .description {
      color: $color2;
    }
  }

  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-column-gap: 3px;
  grid-template-areas: "checkbox description edit delete";
  padding: 3px 0;

  input[type="checkbox"] {
    grid-area: checkbox;
  }

  .description {
    grid-area: description;
    width: 100%;
  }

  .edit {
    grid-area: edit;
  }

  .delete {
    grid-area: delete;
  }
}
