// $color1: hsla(213%, 23%, 38%, 1);
// $color2: hsla(93%, 17%, 10%, 1);
// $color3: hsla(124%, 35%, 18%, 1);
// $color4: hsla(114%, 29%, 27%, 1);
// $color5: hsla(149%, 70%, 75%, 1);
// 
// /* RGB */
// $color1: rgba(74, 94, 119, 1);
// $color2: rgba(26, 31, 22, 1);
// $color3: rgba(30, 63, 32, 1);
// $color4: rgba(52, 88, 48, 1);
// $color5: rgba(148, 236, 190, 1);


/* Coolors Exported Palette - coolors.co/000000-2f4550-586f7c-b8dbd9-f4f4f9 */

/* HSL */
$color1: hsla(0%, 0%, 0%, 1);
$color2: hsla(200%, 26%, 25%, 1);
$color3: hsla(202%, 17%, 42%, 1);
$color4: hsla(177%, 33%, 79%, 1);
$color5: hsla(240%, 29%, 97%, 1);

/* RGB */
$color1: rgba(0, 0, 0, 1);
$color2: rgba(47, 69, 80, 1);
$color3: rgba(88, 111, 124, 1);
$color4: rgba(184, 219, 217, 1);
$color5: rgba(244, 244, 249, 1);

